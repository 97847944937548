@mixin lmn-hover($_color: null, $_backgroundColor: null) {
  @media (hover) {
    &:hover {
      @if ($_color) {
        color: $_color;
      }

      @if ($_backgroundColor) {
        background-color: $_backgroundColor;
      }
    }
  }
}
