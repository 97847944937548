.lmn-clickable {
  cursor: pointer;

  &--touchy {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      bottom: -7px;
      left: -7px;
      right: -7px;
      cursor: pointer;
    }
  }
}
