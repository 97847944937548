.lmn-select {
  @extend .lmn-input;

  border-radius: 0;

  &[size] {
    padding: 0;
  }

  &:not([size]),
  &[size="1"]{
    appearance: none;
    &::-ms-expand{
      display:none;
    }

    padding: $lmn-input-padding;
    padding-right: 2.5rem;
    background-image: url('#{$lmn-image-path}/bg_select.svg');
    background-position: right -55px top -13px;
    background-repeat: no-repeat;
    background-size: 6.5rem 3.4rem;
  }

  &__option {
    appearance: none;
    padding: $lmn-input-padding;
  }

  @media only screen and (pointer: coarse) {
    // we assume a mobile device that does not support expanded select lists but instead
    // displays a single-line select list and provides the options in a native overlay control when clicked
    // thus we need to undo some custom styling for expanded select lists
    &[size]:not([size="1"]) {
      padding: $lmn-input-padding;
    }
  }
}