$lmn-icon-font-size: 1.6em !default;
$lmn-icon-font-size-tiny: 1.2em !default;
$lmn-icon-font-size-small: 1.4em !default;
$lmn-icon-font-size-big: 2.15em !default;
$lmn-icon-font-size-extraBig: 3.15em !default;
$lmn-icon-font-size-screen-tiny: $lmn-icon-font-size-small !default;
$lmn-icon-font-size-small-screen-tiny: $lmn-icon-font-size-small !default;
$lmn-icon-font-size-big-screen-tiny: $lmn-icon-font-size-big !default;
$lmn-icon-font-size-extraBig-screen-tiny: $lmn-icon-font-size-extraBig !default;

.lmn-icon {
  display: inline-block;

  font-size: $lmn-icon-font-size-screen-tiny;
  width: 1em;
  height: 1em;

  &--tiny {
    font-size: $lmn-icon-font-size-tiny;
  }

  &--small {
    font-size: $lmn-icon-font-size-small-screen-tiny;
  }

  &--big {
    font-size: $lmn-icon-font-size-big-screen-tiny;
  }

  &--extraBig {
    font-size: $lmn-icon-font-size-extraBig-screen-tiny;
  }

  &--primary {
    color: $lmn-color-brand-primary;
  }

  &--light {
    color: $lmn-color-gray;
  }

  &--inverted {
    color: $lmn-color-white;
  }

  &--circle {
    border-radius: 50%;
    color: $lmn-color-white;
    background: $lmn-color-black;

    &.lmn-icon--inverted {
      border-radius: 50%;
      color: $lmn-color-black;
      background: $lmn-color-white;
    }
  }

  &--hoverPrimary {
    @include lmn-hover($lmn-color-brand-primary);
  }

  @media #{$lmn-small-up} {
    font-size: $lmn-icon-font-size;

    &--tiny {
      font-size: $lmn-icon-font-size-tiny;
    }

    &--small {
      font-size: $lmn-icon-font-size-small;
    }

    &--big {
      font-size: $lmn-icon-font-size-big;
    }

    &--extraBig {
      font-size: $lmn-icon-font-size-extraBig;
    }
  }
}
