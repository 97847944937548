@mixin lmn-horizontalDefinitionList($mediaQuery) {
  @media #{$mediaQuery} {
    overflow: hidden;

    .lmn-definitionList__term {
      float: left;
      clear: left;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    .lmn-definitionList__definition {
      float: left;
      clear: right;
      margin-bottom: 4px;

      & + .lmn-definitionList__definition {

        margin-top: 0;

        &:before {
          content: '|';
          font-weight: bold;
          margin-right: 0.3em;
          padding-left: 0.3em;
          color: $lmn-color-gray-medium;
        }
      }
    }
  }
}

.lmn-definitionList {

  &__term {
    margin-bottom: 0.6rem;
    font-weight: bold;
    color: $lmn-color-gray;
  }

  .lmn-definitionList__definition {
    margin-bottom: 1.4em;

    & + .lmn-definitionList__definition {
      margin-top: -0.8em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &--darkBackground &__term {
    color: $lmn-color-white;
  }

  &--horizontal {
    @include lmn-horizontalDefinitionList($lmn-tiny-up);
  }

  &--horizontalMediumUp {
    @include lmn-horizontalDefinitionList($lmn-medium-up);
  }

}
