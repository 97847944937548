.lmn-collapsible {

  @keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  display: flex;
  flex-direction: column;

  .lmn-collapsible__statefulVisibility--open {
    display: none;
  }

  .lmn-collapsible__statefulVisibility--collapsed {
    display: initial;
  }

  &__content {
    display: none;
  }

  &[open],
  &.is-open {

    .lmn-collapsible__statefulVisibility--open {
      display: initial;
    }

    .lmn-collapsible__statefulVisibility--collapsed {
      display: none;
    }

    .lmn-collapsible__content {
      display: block;

      &--fade {
        animation: fadeIn 0.5s;
      }
    }
  }


}
