.lmn-legend__item {
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  margin-bottom: 0.1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.lmn-legend__item.is-inactive {
  opacity: 0.5;
}

.lmn-legend__icon {
  max-width: 22px;
  margin-right: $lmn-default-margin-small;
}

.lmn-legend--colored .lmn-legend__item {
  padding: 0.3rem 0.2rem;
}