$lmn-alert-border-color: $lmn-color-gray-light !default;
$lmn-alert-border-width: 1px !default;
$lmn-alert-border-style: solid !default;
$lmn-alert-border-radius: 0 !default;
$lmn-alert-padding: $lmn-default-margin !default;
$lmn-alert-padding-screen-large: $lmn-default-margin-screen-large !default;
$lmn-alert-success-color: $lmn-color-success-inverted !default;
$lmn-alert-success-color-background: $lmn-color-success !default;
$lmn-alert-success-color-border: darken($lmn-alert-success-color-background, 10%) !default;
$lmn-alert-info-color: $lmn-color-black !default;
$lmn-alert-info-color-background: $lmn-color-gray-light !default;
$lmn-alert-info-color-border: darken($lmn-alert-info-color-background, 10%) !default;
$lmn-alert-warning-color: $lmn-color-warning-inverted !default;
$lmn-alert-warning-color-background: $lmn-color-warning !default;
$lmn-alert-warning-color-border: darken($lmn-alert-warning-color-background, 10%) !default;
$lmn-alert-danger-color: $lmn-color-danger-inverted !default;
$lmn-alert-danger-color-background: $lmn-color-danger !default;
$lmn-alert-danger-color-border: darken($lmn-color-danger, 10%) !default;
$lmn-alert-error-color: $lmn-color-error-inverted !default;
$lmn-alert-error-color-background: $lmn-color-error !default;
$lmn-alert-error-color-border: darken($lmn-color-error, 10%) !default;

.lmn-alert {
  padding: $lmn-alert-padding;
  border-width: $lmn-alert-border-width;
  border-style: $lmn-alert-border-style;
  border-radius: $lmn-alert-border-radius;

  border-color: $lmn-alert-border-color;

  @media #{$lmn-medium-up} {
    padding: $lmn-alert-padding-screen-large;
  }
}

.lmn-alert--success {
  color: $lmn-alert-success-color;
  background-color: $lmn-alert-success-color-background;
  border-color: $lmn-alert-success-color-border;

  .lmn-headline,
  .lmn-link,
  .lmn-wysiwyg a {
    color: $lmn-alert-success-color;
  }
}


.lmn-alert--info {
  color: $lmn-alert-info-color;
  background-color: $lmn-alert-info-color-background;
  border-color: $lmn-alert-info-color-border;

  .lmn-headline,
  .lmn-link,
  .lmn-wysiwyg a {
    color: $lmn-alert-info-color;
  }

}

.lmn-alert--warning {
  color: $lmn-alert-warning-color;
  background-color: $lmn-alert-warning-color-background;
  border-color: $lmn-alert-warning-color-border;

  .lmn-headline,
  .lmn-link,
  .lmn-wysiwyg a {
    color: $lmn-alert-warning-color;
  }
}

.lmn-alert--danger {
  color: $lmn-alert-danger-color;
  background-color: $lmn-alert-danger-color-background;
  border-color: $lmn-alert-danger-color-border;

  .lmn-headline,
  .lmn-link,
  .lmn-wysiwyg a {
    color: $lmn-alert-danger-color;
  }
}

.lmn-alert--error {
  color: $lmn-alert-error-color;
  background-color: $lmn-alert-error-color-background;
  border-color: $lmn-alert-error-color-border;

  .lmn-headline,
  .lmn-link,
  .lmn-wysiwyg a {
    color: $lmn-alert-error-color;
  }
}

