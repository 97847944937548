.lmn-radio,
.lmn-checkbox {
  display: block;

  & + & {
    margin-top: .5em;
  }

  &--inline {
    display: inline-block;
    margin-right: $lmn-default-margin;

    margin-top: .5em;
  }


  &__label {
    @include lmn-hover($lmn-color-brand-primary);

    display: inline-block;
    position: relative;

    padding-left: 2em;

    vertical-align: middle;
    cursor: pointer;

    &::before {
      content: '';
      transition: background-color 300ms;
      position: absolute;
      margin-top: -0.175em;
      left: 0;
      width: 1.5em;
      height: 1.5em;
      border: 1px solid transparentize($lmn-color-gray-dark, 0.5);
      padding: .2em;
      color: $lmn-color-white;
      line-height: .4em;
      text-indent: -.1em;
    }
  }

  &__control {
    // do not use display: none because that would prevent the control from being tab-activatable (it would lose its tabindex so to say)
    position: absolute;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &__control:focus + &__label::before {
    border-width: 2px;
  }

  &.is-checked &__label,
  &__control:checked + &__label {
    color: $lmn-color-brand-primary;
  }

  &.is-checked &__label::before,
  &__control:checked + &__label::before {
    background-image: url('#{$lmn-image-path}/check.svg');
    background-color: $lmn-color-brand-primary;
    color: $lmn-color-brand-primary-inverted;
    background-size: 1em;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--big &__label {

    font-size: 1.1em;
    padding-left: 2.3em;

    &::before {
      margin-top: -0.24em;
      border-width: 2px;
      line-height: 0.4em;
      text-indent: -0.2em;
      font-size: 1.2em;
    }
  }

  &--big {

    margin-right: $lmn-default-margin-big;

    & + & {
      margin-top: 1em;
    }

  }

  &--big.lmn-checkbox--inline,
  &--big.lmn-radio--inline {
    & + & {
      margin-top: 0;
    }
  }

  &--small &--inline {
    margin-right: $lmn-default-margin-small;
  }

  &--small &__label {
    font-size: 0.8em;
  }

}

.lmn-radio__label::before {
  border-radius: 50%;
}
