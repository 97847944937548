// scss-lint:disable ImportantRule
.lmn-color {

  &--primary {
    color: $lmn-color-brand-primary;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-brand-primary !important;
    }
  }

  &--secondary {
    color: $lmn-color-brand-secondary;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-brand-secondary !important;
    }
  }

  &--gray {
    color: $lmn-color-gray;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-gray !important;
    }
  }

  &--mediumGray {
    color: $lmn-color-gray-medium;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-gray-medium !important;
    }
  }

  &--white {
    color: $lmn-color-white;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-white !important;
    }
  }

  &--error {
    color: $lmn-color-error;

    &.lmn-color--force,
    &.lmn-color--force * {
      color: $lmn-color-error !important;
    }
  }
}
