.lmn-loadingContainer {
  position: relative;

  &__loader {
    transition: background-color 500ms;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background-color: $lmn-color-transparent;
  }

  &__dialog {
    @include lmn-roundedEdges;
    transition: transform 300ms;
    transform: scale(0);
    padding: $lmn-default-margin $lmn-default-margin $lmn-default-margin * 0.8 $lmn-default-margin;
    pointer-events: none;
    background-color: $lmn-color-darken-heavy;
  }

  &__loadingIndicator {
    display: none;
    width: 25px;
    height: 25px;
  }

  &__loadingMessage {
    float: left;
    color: $lmn-color-white;
    line-height: 1.5rem;
    margin-right: $lmn-default-margin;
  }

  &.is-loading {

    .lmn-loadingContainer__loader {
      z-index: $lmn-loading-container-z-index;
      opacity: 1;
    }

    .lmn-loadingContainer__dialog {
      transform: scale(1);
    }

    .lmn-loadingContainer__loadingIndicator {
      display: block;
      pointer-events: all;
    }
  }

  &.is-blocking {
    > .lmn-loadingContainer__loader {
      pointer-events: all;
      background-color: $lmn-color-darken;
    }
  }
}
