.lmn-centered {
  display: flex;


  &--vertically {
    align-items: center;
  }

  &--horizontally {
    flex-direction: column;
    align-items: center;

    &.lmn-centered--vertically {
      justify-content: center;
    }
  }
}
