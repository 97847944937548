$lmn-paragraph-margin: $lmn-default-margin !default;
$lmn-paragraph-lineHeight: 1.75em !default;

.lmn-paragraph {
  margin-bottom: $lmn-paragraph-margin;
  line-height: $lmn-paragraph-lineHeight;
  font-variant-numeric: oldstyle-nums;

  &--noMargin {
    margin-bottom: 0;
  }
}
