.lmn-loadingIndicator {
  animation: rotate 3.5s linear infinite;
  transform-origin: center center;

  &__path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $lmn-color-brand-primary;
    }

    40% {
      stroke: $lmn-color-brand-primary;
    }

    66% {
      stroke: $lmn-color-gray-light;
    }

    80%,
    90% {
      stroke: $lmn-color-gray-light;
    }
  }
}
