.lmn-hidden-tiny {
  @include lmn-hidden-tiny;
}

.lmn-hidden-tiny-up {
  @include lmn-hidden-tiny-up;
}

.lmn-hidden-tiny-down {
  @include lmn-hidden-tiny-down;
}


.lmn-hidden-small {
  @include lmn-hidden-small;
}

.lmn-hidden-small-up {
  @include lmn-hidden-small-up;
}

.lmn-hidden-small-down {
  @include lmn-hidden-small-down;
}


.lmn-hidden-medium {
  @include lmn-hidden-medium;
}

.lmn-hidden-medium-up {
  @include lmn-hidden-medium-up;
}

.lmn-hidden-medium-down {
  @include lmn-hidden-medium-down;
}


.lmn-hidden-large {
  @include lmn-hidden-large;
}

.lmn-hidden-large-up {
  @include lmn-hidden-large-up;
}

.lmn-hidden-large-down {
  @include lmn-hidden-large-down;
}

.lmn-hidden-xlarge {
  @include lmn-hidden-xlarge;
}

.lmn-hidden-xlarge-up {
  @include lmn-hidden-xlarge-up;
}

.lmn-hidden-xlarge-down {
  @include lmn-hidden-xlarge-down;
}

.lmn-hidden-screen {
  @include lmn-hidden-screen;
}

.lmn-hidden-print {
  @include lmn-hidden-print;
}
