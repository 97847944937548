.lmn-progressBar {
  width: 100%;
  min-width: 7em;
  font-variant-numeric: oldstyle-nums;

  &:before {
    content: '';
    display: inline-block;
    width: 90%;
    width: calc(100% - 3.5em);
    height: 0.4rem;
    background: $lmn-color-gray-medium;
    margin-right: 0.5rem;
  }


  &--10:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 10%, $lmn-color-gray-medium 0%);
  }
  &--20:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 20%, $lmn-color-gray-medium 0%);
  }
  &--30:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 30%, $lmn-color-gray-medium 0%);
  }
  &--40:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 40%, $lmn-color-gray-medium 0%);
  }
  &--50:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 50%, $lmn-color-gray-medium 0%);
  }
  &--60:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 60%, $lmn-color-gray-medium 0%);
  }
  &--70:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 70%, $lmn-color-gray-medium 0%);
  }
  &--80:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 80%, $lmn-color-gray-medium 0%);
  }
  &--90:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 90%, $lmn-color-gray-medium 0%);
  }
  &--100:before {
    background: linear-gradient(90deg, $lmn-color-brand-primary 100%, $lmn-color-gray-medium 0%);
  }
}
