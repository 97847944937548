$lmn-lqiGrade-color-1: #309AFE !default;
$lmn-lqiGrade-color-2: #63CFFE !default;
$lmn-lqiGrade-color-3: #9DFFFF !default;
$lmn-lqiGrade-color-4: #FFFF9D !default;
$lmn-lqiGrade-color-5: #FF9B3B !default;
$lmn-lqiGrade-color-6: #FF2F32 !default;

$lmn-lqiGrade-color-1-inverted: $lmn-color-black !default;
$lmn-lqiGrade-color-2-inverted: $lmn-color-black !default;
$lmn-lqiGrade-color-3-inverted: $lmn-color-black !default;
$lmn-lqiGrade-color-4-inverted: $lmn-color-black !default;
$lmn-lqiGrade-color-5-inverted: $lmn-color-black !default;
$lmn-lqiGrade-color-6-inverted: $lmn-color-white !default;


.lmn-lqiGrade {
  $self: &;

  &--background {
    color: $lmn-color-black;
    background-color: $lmn-color-gray;
  }

  &--text {
    color: $lmn-color-gray;
  }

  &--underline {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: $lmn-color-gray;
  }

  &--1 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-1-inverted;
      background-color: $lmn-lqiGrade-color-1;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-1;
    }
    
    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-1;
    }
  }

  &--2 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-2-inverted;
      background-color: $lmn-lqiGrade-color-2;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-2;
    }

    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-2;
    }
  }

  &--3 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-3-inverted;
      background-color: $lmn-lqiGrade-color-3;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-3;
    }

    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-3;
    }
  }

  &--4 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-4-inverted;
      background-color: $lmn-lqiGrade-color-4;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-4;
    }

    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-4;
    }
  }

  &--5 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-5-inverted;
      background-color: $lmn-lqiGrade-color-5;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-5;
    }

    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-5;
    }
  }

  &--6 {
    &#{$self}--background {
      color: $lmn-lqiGrade-color-6-inverted;
      background-color: $lmn-lqiGrade-color-6;
    }

    &#{$self}--text {
      color: $lmn-lqiGrade-color-6;
    }

    &#{$self}--underline {
      border-color: $lmn-lqiGrade-color-6;
    }
  }

}
