@font-face {
  font-family: 'Hamburg Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$lmn-asset-path}/client/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Hamburg Sans';
  font-style: normal;
  font-weight: 700;
  src: url('#{$lmn-asset-path}/client/fonts/OpenSans/OpenSans-Bold.ttf');
}
