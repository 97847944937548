$lmn-button-primary-color-background: $lmn-color-brand-primary !default;
$lmn-button-primary-color: $lmn-color-brand-primary-inverted !default;
$lmn-button-primary-border-color: $lmn-button-primary-color-background !default;
$lmn-button-primary-hover-color-background: $lmn-color-brand-primary-light !default;
$lmn-button-primary-hover-color: $lmn-button-primary-color !default;
$lmn-button-primary-hover-border-color: $lmn-button-primary-hover-color-background !default;

$lmn-button-secondary-color-background: $lmn-color-gray-dark !default;
$lmn-button-secondary-color: $lmn-color-white !default;
$lmn-button-secondary-border-color: $lmn-color-gray-dark !default;
$lmn-button-secondary-hover-color-background: $lmn-color-gray-darker !default;
$lmn-button-secondary-hover-color: $lmn-color-white !default;
$lmn-button-secondary-hover-border-color: $lmn-button-secondary-hover-color-background !default;

$lmn-button-danger-color-background: $lmn-color-error !default;
$lmn-button-danger-color: $lmn-color-white !default;
$lmn-button-danger-border-color: $lmn-button-danger-color-background !default;
$lmn-button-danger-hover-color-background: darken($lmn-color-error, 10%) !default;
$lmn-button-danger-hover-color: $lmn-button-danger-color !default;
$lmn-button-danger-hover-border-color: $lmn-button-danger-hover-color-background !default;

$lmn-button-padding: 0.75em 0.75em 0.55em 0.75em !default;
$lmn-button-padding-big: 1.0em 1.4em 0.9em 1.4em !default;

$lmn-button-font-weight: bold;
$lmn-button-text-transform: uppercase;

$lmn-button-font-size: 0.9em !default;
$lmn-button-font-size-big: 1.0em !default;
$lmn-button-border-radius: $lmn-roundedEdge-border-radius !default;

@mixin button-colors(
  $_color,
  $_backgroundColor,
  $_borderColor,
  $_hoverColor,
  $_hoverBackgroundColor,
  $_hoverBorderColor) {
  background-color: $_backgroundColor;
  color: $_color;
  border-color: $_borderColor;

  &:visited {
    color: $_color;
  }

  &:not([disabled]) {
    @include lmn-hover($_hoverColor, $_hoverBackgroundColor);
  }

  &:not([disabled]):not(:hover):active,
  &:not([disabled]):not(:hover):focus {
    color: $_color;
  }
}

.lmn-button {
  transition: background-color 200ms, border-color 200ms, color 200ms;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: $lmn-button-padding;
  border: 1px solid transparent;
  border-radius: $lmn-button-border-radius;

  text-transform: $lmn-button-text-transform;
  font-size: $lmn-button-font-size;
  font-weight: $lmn-button-font-weight;
  line-height: 1em;
  cursor: pointer;
}

.lmn-button:focus {
  outline: none;
}

.lmn-button[disabled],
.lmn-button.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.lmn-button--block {
  width: 100%;
  justify-content: center;
}

.lmn-button--primary {
  @include button-colors(
                  $lmn-button-primary-color,
                  $lmn-button-primary-color-background,
                  $lmn-button-primary-border-color,
                  $lmn-button-primary-hover-color,
                  $lmn-button-primary-hover-color-background,
                  $lmn-button-primary-hover-border-color
  );
}

.lmn-button--secondary {
  @include button-colors(
                  $lmn-button-secondary-color,
                  $lmn-button-secondary-color-background,
                  $lmn-button-secondary-border-color,
                  $lmn-button-secondary-hover-color,
                  $lmn-button-secondary-hover-color-background,
                  $lmn-button-secondary-hover-border-color
  );
}

.lmn-button--danger {
  @include button-colors(
                  $lmn-button-danger-color,
                  $lmn-button-danger-color-background,
                  $lmn-button-danger-border-color,
                  $lmn-button-danger-hover-color,
                  $lmn-button-danger-hover-color-background,
                  $lmn-button-danger-hover-border-color
  );
}

.lmn-button__icon {

  //margin-top: -0.4em;
  //margin-bottom: -0.2em;

  &--left {
    margin-right: 0.3em;
    margin-left: -0.2em;
  }

  &--right {
    margin-left: 0.3em;
    margin-right: -0.2em;
  }
}


.lmn-button--big {
  padding: $lmn-button-padding-big;
  font-size: $lmn-button-font-size-big;
}
