  .lmn-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__tabHeaders {
      display: flex;
      flex: 0 0 auto;
    }

    &__tabHeader {
      @include lmn-hover($lmn-color-brand-primary);

      position: relative;
      flex: 1 1 0;
      transition: color 200ms, border 200ms;
      padding: 11px 8px 8px 8px;
      border-bottom: 2px solid $lmn-color-gray-light;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 0.87 * $lmn-font-size-base;

      &::after {
        content: '';
        position: absolute;
        right: -1px;
        top: 6px;
        bottom: 6px;
        width: 2px;
        background-color: $lmn-color-gray-light;
      }

      &.is-active,
      &:focus {
        font-weight: bold;
        font-size: $lmn-font-size-base;
        color: $lmn-color-brand-primary;
        border-color: $lmn-color-brand-primary;
      }

      &:first-child,
      &--first {
        margin-left: 0;
      }

      &:last-child,
      &--last {
        margin-right: 0;

        // scss-lint:disable NestingDepth
        &::after {
          display: none;
        }
        // scss-lint:enable NestingDepth

      }
    }

    &__tabContent {
      flex: 1 1 auto;
      display: none;

      &.is-active {
        display: block;
      }
    }
  }
