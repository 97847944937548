.lmn-map {
  position: relative;
  display: block;
  height: 100%;
  background-color: $lmn-color-gray-lighter;
}

.lmn-map__overlay {
  font-family: $lmn-font-family-base;
  transition: background-color 300ms;
  position: absolute;
  z-index: $lmn-map-z-index;
  //pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
  padding: $lmn-default-margin-small;
}

.lmn-map__overlay:hover {
  background-color: rgba(255, 255, 255, 1);
}

.lmn-map__overlay--top {
  top: 0;
}

.lmn-map__overlay--right {
  right: 0;
}

.lmn-map__overlay--bottom {
  bottom: 15px;
}

.lmn-map__overlay--left {
  left: 0;
}

.lmn-map__lqi.is-lqi-1 {
  color: $lmn-color-lqi1;
}
.lmn-map__lqi.is-lqi-2 {
  color: $lmn-color-lqi2;
}
.lmn-map__lqi.is-lqi-3 {
  color: $lmn-color-lqi3;
}
.lmn-map__lqi.is-lqi-4 {
  color: $lmn-color-lqi4;
}
.lmn-map__lqi.is-lqi-5 {
  color: $lmn-color-lqi5;
}
.lmn-map__lqi.is-lqi-6 {
  color: $lmn-color-lqi6;
}

.lmn-map__marker {
  position: relative;
  font-size: 14px; /* avoid inheriting font size from leaflet maps */
}
.lmn-map__markerIcon {
  color: $lmn-color-gray-light;
  stroke: $lmn-color-gray;
  font-size: 3.1em;
}
.lmn-map__markerIcon--small {
  font-size: 2.2em;
}
.lmn-map__markerLabel {
  position: absolute;
  top: 14%;
  left: 0;
  right: 0;
  text-align: center;
  font-family: $lmn-font-family-base;
  font-size: 0.8em;
  font-weight: bold;
}

.lmn-map__markerLabel--secondary {
  top: 50%;
  font-size: 0.7em;
  font-weight: normal;
}

.leaflet-control-layers,
.leaflet-bar {
  border: none !important;
}

.lmn-map__marker.is-active .lmn-map__markerIcon {
  stroke: $lmn-color-black;
}