.lmn-pagination {
  display: flex;

  &__item {
    @include lmn-hover($lmn-color-brand-primary-inverted, $lmn-color-brand-primary);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    font-size: $lmn-font-size-base * 0.8;
    color: $lmn-color-brand-primary;

    //Because center is apparently not centered enough..
    padding-top: 3px;

    &.is-active {
      color: $lmn-color-brand-primary-inverted;
      background-color: $lmn-color-brand-primary;
    }
  }

}
