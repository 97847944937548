$lmn-inputGroup-error-color: $lmn-color-white !default;
$lmn-inputGroup-error-background-color: $lmn-color-error !default;
$lmn-inputGroup--constrained-max-width: 600px;

.lmn-inputGroup {
  position: relative;
  display: block;

  &.has-errors {
    border: 1px solid $lmn-color-error;
  }

  & + & {
    margin-top: $lmn-default-margin-big;
  }

  &--constrainedWidth {
    @media #{$lmn-large-up} {
      max-width: $lmn-inputGroup--constrained-max-width;
    }
  }

  &__label {
    margin-left: 0.1em;
    margin-bottom: 0.3em;
    font-size: 1em;
    font-weight: bold;
    color: $lmn-color-gray;
  }

  &__subtext {
    display: block;
    width: 100%;
    padding: 5px 3px 3px 5px;
    font-size: 0.8rem;

    &--error {
      background-color: $lmn-inputGroup-error-background-color;
      color: $lmn-inputGroup-error-color;
    }
  }

  &__alignedButton {
    margin-top: 20px;
  }
}
