$lmn-input-error-color: $lmn-color-error;
$lmn-input-valid-color: $lmn-color-success;
$lmn-input-border-color: $lmn-color-gray-medium;

.lmn-input {
  flex: 1 1 auto;
  width: 100%;

  outline: 0;
  padding: $lmn-input-padding;


  background-color: $lmn-color-white;
  color: $lmn-color-gray-dark;

  border: 1px solid $lmn-input-border-color;

  &:focus {
    border: 1px solid $lmn-color-brand-primary;
  }

  // scss-lint:disable VendorPrefix, PseudoElement
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &-moz-placeholder,
  &-ms-input-placeholder {
    font-size: 0.8em;
  }
  // scss-lint:enable VendorPrefix, PseudoElement

  &.is-disabled,
  &--stateful[disabled] {
    border-color: $lmn-color-gray-medium;
    color: $lmn-color-gray-medium;
    cursor: not-allowed;
  }

  &.is-readonly,
  &--stateful[readonly] {
    border-color: $lmn-color-gray-medium;
    color: $lmn-color-gray;
  }

  &.is-hidden {
    /* Hide input element while still preserving accessibility features (i.e. tab order) and ensuring that its value gets submitted properly.
       CSS properties such as display: none or visibility: hidden will not work out. The reasons are:
        - the input value will not be sent to the server on form submit
        - the input will be excluded out of tab order.
    */
    width: 0.1px; //Setting the property values to zero ends up throwing the element out of tab party in some browsers.
    height: 0.1px; //Setting the property values to zero ends up throwing the element out of tab party in some browsers.
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &--stateful {
    &.is-invalid,
    &.ng-touched.ng-invalid {
      border-color: $lmn-color-error;
    }
  }

  &--big {
    padding: 12px 5px;
  }
}


textarea.lmn-input {
  min-height: 7em;
}
