.lmn-pageWrapper {
  max-width: $lmn-page-max-width;
  margin-left: auto;
  margin-right: auto;
  line-height: $lmn-line-height-base;
  font-family: $lmn-font-family-base;
  font-size: $lmn-font-size-text;
  color: $lmn-text-color;
  background-color: $lmn-body-bg;

  &--fullWidth {
    max-width: 100%;
  }
}
