$lmn-table-color-background: $lmn-color-white !default;
$lmn-table-headline-color-background: $lmn-color-gray-lighter !default;
$lmn-table-headline-color: $lmn-color-black !default;
$lmn-table-headline-font-weight: normal !default;
$lmn-table-highlight-color-background: $lmn-color-gray !default;
$lmn-table-highlight-color: $lmn-color-white !default;
$lmn-table-border-color: $lmn-color-gray-light;
$lmn-table-hover-background-color: $lmn-color-gray-light !default;
$lmn-table-font-size: 0.9em !default;
$lmn-table-font-size-compact: 0.8em !default;

@mixin responsiveTable($_mediaQuery) {
  @media #{$_mediaQuery} {
    &,
    & .lmn-table__row,
    & .lmn-table__cell,
    & tbody {
      display: block;
    }

    & {
      border-right: none;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    & thead {
      position: absolute;
      top: -999rem;
      left: -999rem;
    }

    & .lmn-table__row {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      border-bottom: 2px solid $lmn-color-gray;

      //background-color: transparent !important; //"unstripe"

      &:first-child {
        border-top: 2px solid $lmn-color-gray;
      }
    }

    & .lmn-table__cell {
      /* Behave  like a "row" */
      display: flex;
      align-items: flex-start;
      border: none;
      border-bottom: 1px solid $lmn-color-gray-light;

      &:last-child {
        border-bottom: none;
      }

      &::before {
        content: attr(data-lmn-th);
        flex-basis: 35%;
        flex-shrink: 0;
        margin-right: 1em;
        text-align: right;
        font-weight: bold;
      }

      &--headline::before {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      &--contentAsResponsiveHeadline {
        display: block;
        font-size: 1.2em;
        font-weight: bold;
        padding-left: 0.3rem;

        &.lmn-table__cell--headline {
          text-align: left;
          border-bottom: none;
          margin-bottom: 1rem;
        }

        &::before {
          display: none;
        }
      }

      &--noResponsiveHeadline {
        display: block;
        padding-left: 0.3rem;

        &.lmn-table__cell--headline {
          border-bottom: none;
          margin-bottom: 1rem;
        }

        &::before {
          display: none;
        }
      }

      &--centered {
        text-align: left; /* do not center in responsive mode */
      }
    }
  }
}

.lmn-tableWrapper {
  position: relative;
  background-color: $lmn-table-color-background;
}

.lmn-table {
  border-collapse: collapse;
  border-spacing: 0;
  font-variant-numeric: lining-nums tabular-nums;
  border-right: 2px solid $lmn-color-white;
  background-color: $lmn-table-color-background;
  font-size: $lmn-table-font-size;
}

.lmn-table--block {
  width: 100%;
}

.lmn-table--hover .lmn-table__row:hover {
  background-color: $lmn-table-hover-background-color;
}

.lmn-table--bordered .lmn-table__cell {
  border-bottom: 1px solid $lmn-table-border-color;
}

.lmn-table__row {
  transition: background-color 300ms;
  border-bottom: 1px solid $lmn-color-gray-lighter;
}

.lmn-table__cell {
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  vertical-align: top;
  text-align: left;

  &--centered {
    text-align: center;
  }

  &--centeredVertically {
    vertical-align: center;
  }

  &--headline {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: $lmn-table-headline-color-background;
    color: $lmn-table-headline-color;
    font-weight: $lmn-table-headline-font-weight;
  }

  &--highlight {
    background-color: $lmn-table-highlight-color-background;
    color: $lmn-table-highlight-color;
  }

  &--numeric {
    text-align: right;
  }

  &--headlineRow {
    text-align: right;
  }

  &--image {
    padding-top: 0.5rem;
  }

  &--actions {
    text-align: right;
    white-space: nowrap;
  }
}

.lmn-table__action {
  color: $lmn-color-gray;
  font-size: 0.8em;

  &:hover {
    color: $lmn-color-brand-primary;
  }

  &:visited {
    color: $lmn-color-gray;
  }

  & + .lmn-table__action {
    margin-left: 0.5em;
  }
}

.lmn-table--compact {
  font-size: $lmn-table-font-size-compact;

  .lmn-table__cell {
    padding-top: 0.4rem;
    padding-left: 0.25rem;
    padding-bottom: 0.3rem;
    padding-right: 0.2rem;
  }
}

@media #{$lmn-print-only} {
  .lmn-table--bordered {
    .lmn-table__cell {
      border-color: $lmn-color-black !important; // sass-lint:disable-line no-important
    }
  }
}

.lmn-table--responsiveMediumDown {
  @include responsiveTable($lmn-medium-down);
}

.lmn-table--responsiveSmallDown {
  @include responsiveTable($lmn-small-down);
}
