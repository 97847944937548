$lmn-col-margin: 14px !default;
$lmn-col-margin-small: 8px !default;
$lmn-col-margin-big: 28px !default;

.lmn-col {
  display: inline-flex;

  margin-right: $lmn-col-margin;

  &--smallMargin {
    margin-right: $lmn-col-margin-small;
  }

  &--bigMargin {
    margin-right: $lmn-col-margin-big;
  }

  &--withSeparator {
    border-right: 1px solid $lmn-color-gray-medium;
    padding-right: calc(#{$lmn-col-margin} / 1.65);
    margin-right: calc(#{$lmn-col-margin} / 2.5);

    &.lmn-col--smallMargin {
      padding-right: calc(#{$lmn-col-margin-small} / 1.4);
      margin-right: calc(#{lmn-col-margin-small} / 2.8);
    }

    &.lmn-col--bigMargin {
      padding-right: calc(#{$lmn-col-margin-big} / 1.75);
      margin-right: calc(#{$lmn-col-margin-big} / 2.5);
    }
  }

  &--inverse {

    margin-right: 0;
    margin-left: $lmn-col-margin;

    &.lmn-col--withSeparator {
      padding-right: 0;
      border-right: none;

      border-left: 1px solid $lmn-color-gray-medium;
      padding-left: calc(#{$lmn-col-margin} / 1.65);
      margin-left: calc(#{$lmn-col-margin} / 2.5);

      &.lmn-col--smallMargin {
        padding-left: calc(#{$lmn-col-margin-small} / 1.4);
        margin-left: calc(#{$lmn-col-margin-small} / 2.8);
      }

      &.lmn-col--bigMargin {
        padding-left: calc(#{$lmn-col-margin-big} / 1.75);
        margin-left: calc(#{$lmn-col-margin-big} / 2.5);
      }
    }
  }

}
