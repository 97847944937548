$lmn-region-padding: $lmn-default-margin !default;
$lmn-region-padding-screen-large: $lmn-default-margin-screen-large !default;

$lmn-region-small-padding: $lmn-default-margin-small !default;
$lmn-region-small-padding-screen-large: $lmn-default-margin-small-screen-large !default;

$lmn-region-big-padding: $lmn-default-margin-big !default;
$lmn-region-big-padding-screen-large: $lmn-default-margin-big-screen-large !default;

$lmn-region-primary-color: $lmn-color-brand-primary-inverted !default;
$lmn-region-primary-color-background: $lmn-color-brand-primary !default;

$lmn-region-secondary-color: $lmn-color-black !default;
$lmn-region-secondary-color-background: $lmn-color-gray-lighter !default;

.lmn-region {
  padding: $lmn-region-padding;

  @media #{$lmn-large-up} {
    padding: $lmn-region-padding-screen-large;
  }

  &--small {
    padding: $lmn-region-small-padding;

    @media #{$lmn-large-up} {
      padding: $lmn-region-small-padding-screen-large;
    }
  }

  &--focusable:focus-within {
    box-shadow: $lmn-color-gray 0 0 3px;
  }

  &--big {
    padding: $lmn-region-big-padding;

    @media #{$lmn-large-up} {
      padding: $lmn-region-big-padding-screen-large;
    }
  }

  &--primary {
    background: $lmn-region-primary-color-background;
    color: $lmn-region-primary-color;
  }

  &--secondary {
    background: $lmn-region-secondary-color-background;
    color: $lmn-region-secondary-color;
  }

  &--default {
    background: $lmn-color-white;
    color: $lmn-color-black;
  }

  &--shineThroughLight {
    background: $lmn-color-lighten-heavy;
    color: $lmn-color-black;
  }

  &--depth {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  }

  &--rounded {
    @include lmn-roundedEdges;
  }
}
