$lmn-list-default-bullet-icon: '■';

.lmn-list {

  &--bulleted {
    > li,
    .lmn-list__item {
      position: relative;
      padding-left: 1.2em;
      line-height: 1.4em;
      margin-bottom: 1em;

      &::before {
        content: $lmn-list-default-bullet-icon;
        position: absolute;
        left: 0;
        top: 0.3em;
        line-height: 0.7em;
        color: $lmn-color-brand-primary;
      }
    }

  }

  &--numbered {
    list-style-type: decimal;
    list-style-position: inside;

    > li,
    .lmn-list__item {
      line-height: 1em;
      margin-bottom: 0.8em;
    }
  }

}
