.lmn-hiddenUninitialized {

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  opacity: 0;

  // scss-lint:disable QualifyingElement
  .is-lmn-initialized & {
    animation: fadeIn 0.5s;
    opacity: 1;
  }
  // scss-lint:enable QualifyingElement

}
