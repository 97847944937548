html.is-lmn-modal-open,
html.is-lmn-modal-open body {
  overflow: hidden;
}

.lmn-modal {

  $lmn-modal-margin-sides: 8px;
  $lmn-modal-margin-top: 50px; /* prevent iOS Safari top bar overlapping */
  $lmn-modal-margin-bottom: 50px; /* prevent iOS Safari bottom bar overlapping */

  $lmn-modal-margin-sides-screenLarge: 16px;
  $lmn-modal-margin-top-screenLarge: 16px;
  $lmn-modal-margin-bottom-screenLarge: 16px;

  @keyframes bounceInDown {
    from, 60%, 75%, 90%, to {
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
    }

    75% {
      transform: translate3d(0, -10px, 0);
    }

    90% {
      transform: translate3d(0, 5px, 0);
    }

    to {
      transform: none;
    }
  }

  html:not(.csspointerevents) & {
    /*
    needed for browsers that do not support pointer-events: none (IE <= 10)
    until pointer-events polyfill has been initialized
    */
    z-index: -999;

    &.is-open {
      z-index: $lmn-modal-z-index;
    }
  }

  will-change: opcity;
  transition: opacity 250ms;
  transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: $lmn-modal-z-index;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  background-color: $lmn-color-darken-heavy;
  font-size: $lmn-font-size-text;

  &--fullWidth &__dialog {
    width: 100%;
  }

  &--fullHeight &__dialog {
    height: 100%;
  }

  &__dialog {
    @include lmn-roundedEdges();

    animation-duration: 1s;
    animation-fill-mode: both;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-height: calc(100vh - #{$lmn-modal-margin-top} - #{$lmn-modal-margin-bottom});;
    max-width: 100%;
    max-width: 100vw;
    margin: $lmn-modal-margin-top $lmn-modal-margin-sides $lmn-modal-margin-bottom $lmn-modal-margin-sides;
    background-color: $lmn-color-white;

    @media #{$lmn-medium-up} {
      max-height: calc(100vh - #{$lmn-modal-margin-top-screenLarge} - #{$lmn-modal-margin-bottom-screenLarge});
      margin: $lmn-modal-margin-top-screenLarge $lmn-modal-margin-sides-screenLarge $lmn-modal-margin-bottom-screenLarge $lmn-modal-margin-sides-screenLarge;
    }
  }

  &__header {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    padding: 12px 15px 10px 15px;
    border-bottom: 1px solid $lmn-color-gray-medium;
    background-color: $lmn-color-brand-primary;
    color: $lmn-color-brand-primary-inverted;

    &Content {
      flex: 1 1 100%;
      text-align: center;
    }

    &Action,
    &Close {
      position: relative;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }
  }

  &__content {
    flex: 1 1 auto;
    max-width: 97vw;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &__footerButtons {
    border-top: 1px solid $lmn-color-gray-medium;
    padding-top: 1.1rem;
    padding-bottom: 0.9rem;
    text-align: center;
  }

  &.is-open {
    opacity: 1;
    pointer-events: all;

    .lmn-modal__dialog {
      animation-name: bounceInDown;
    }
  }
}
