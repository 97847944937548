.lmn-wysiwyg {

  p {
    display: block;
    margin-bottom: $lmn-default-margin-small;
  }

  i {
    font-style: italic;
  }

  b, strong {
    font-weight: bold;
  }

  a {
    @include lmn-link();
  }
}