$lmn-grid-gutter-width: 1.2rem !default;
$lmn-grid-gutter-height: 1.0rem !default;
$lmn-grid-gutter-width-halve: $lmn-grid-gutter-width / 2;
$lmn-grid-gutter-height-halve: $lmn-grid-gutter-height / 2;


.lmn-grid__example {
  color: $lmn-color-brand-primary-inverted;
  background-color: $lmn-color-brand-primary;
}

.lmn-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex: 1 1 auto;

  &--vertical {
    height: 100%;
    flex-direction: column;
  }

  &--gutters {
    margin-left: -$lmn-grid-gutter-width-halve;
    margin-right: -$lmn-grid-gutter-width-halve;

    > .lmn-grid__cell {
      padding: $lmn-grid-gutter-height-halve $lmn-grid-gutter-width-halve $lmn-grid-gutter-height-halve $lmn-grid-gutter-width-halve;
      background-clip: content-box;
    }
  }

  &--columnGutters {
    margin-left: -$lmn-grid-gutter-width-halve;
    margin-right: -$lmn-grid-gutter-width-halve;

    > .lmn-grid__cell {
      padding-left: $lmn-grid-gutter-width-halve;
      padding-right: $lmn-grid-gutter-width-halve;
      background-clip: content-box;
    }
  }


  &--rowGutters {
    > .lmn-grid__cell {
      padding-top: $lmn-grid-gutter-height-halve;
      padding-bottom: $lmn-grid-gutter-height-halve;
      background-clip: content-box;
    }
  }

  // scss-lint:disable NestingDepth
  &--bigGutters {
    &.lmn-grid--gutters {
      margin-left: -$lmn-grid-gutter-width;
      margin-right: -$lmn-grid-gutter-width;

      > .lmn-grid__cell {
        padding: $lmn-grid-gutter-height $lmn-grid-gutter-width $lmn-grid-gutter-height $lmn-grid-gutter-width;
      }
    }

    &.lmn-grid--columnGutters {
      margin-left: -$lmn-grid-gutter-width;
      margin-right: -$lmn-grid-gutter-width;

      > .lmn-grid__cell {
        padding-left: $lmn-grid-gutter-width;
        padding-right: $lmn-grid-gutter-width;
      }
    }


    &.lmn-grid--rowGutters {
      > .lmn-grid__cell {
        padding-top: $lmn-grid-gutter-height;
        padding-bottom: $lmn-grid-gutter-height;
      }
    }
  }
  // scss-lint:enable NestingDepth

  &--centerWidowLine {
    justify-content: space-around;
  }

  &__cell {
    flex: 1;
    min-width: 0;

    /* alignment per cell */
    &--top {
      align-self: flex-start;
    }

    &--bottom {
      align-self: flex-end;
    }

    &--center {
      align-self: center;
    }


    /* cell ordering */
    &--orderFirst {
      order: -1;
    }

    &--orderLast {
      order: 999;
    }

    /* width per cell */
    &--shrink {
      flex-basis: auto;
      flex-grow: 0;
    }

    &--double {
      flex-grow: 2;
    }

    &--triple {
      flex-grow: 3;
    }

    &--1of12 {
      flex: 0 0 auto;
      width: 1 / 12 * 100%;
    }

    &--2of12,
    &--1of6 {
      flex: 0 0 auto;
      width: 2 / 12 * 100%;
    }

    &--3of12,
    &--1of4 {
      flex: 0 0 auto;
      width: 3 / 12 * 100%;
    }

    &--4of12,
    &--2of6,
    &--1of3 {
      flex: 0 0 auto;
      width: 4 / 12 * 100%;
    }

    &--5of12 {
      flex: 0 0 auto;
      width: 5 / 12 * 100%;
    }

    &--half,
    &--6of12,
    &--3of6,
    &--2of4,
    &--1of2 {
      flex: 0 0 auto;
      width: 6 / 12 * 100%;
    }

    &--7of12 {
      flex: 0 0 auto;
      width: 7 / 12 * 100%;
    }

    &--8of12,
    &--4of6,
    &--2of3 {
      flex: 0 0 auto;
      width: 8 / 12 * 100%;
    }

    &--9of12,
    &--3of4 {
      flex: 0 0 auto;
      width: 9 / 12 * 100%;
    }

    &--10of12,
    &--5of6 {
      flex: 0 0 auto;
      width: 10 / 12 * 100%;
    }

    &--11of12 {
      flex: 0 0 auto;
      width: 11 / 12 * 100%;
    }

    &--full,
    &--12of12,
    &--6of6,
    &--4of4,
    &--3of3,
    &--2of2,
    &--1of1 {
      flex: 0 0 auto;
      width: 100%;
    }


    @media #{$lmn-tiny-up} {

      &--tiny-orderFirst {
        order: -1;
      }

      &--tiny-orderLast {
        order: 999;
      }

      &--tiny-double {
        flex-grow: 2;
      }

      &--tiny-triple {
        flex-grow: 3;
      }

      &--tiny-1of12 {
        flex: 0 0 auto;
        width: 1 / 12 * 100%;
      }

      &--tiny-2of12,
      &--tiny-1of6 {
        flex: 0 0 auto;
        width: 2 / 12 * 100%;
      }

      &--tiny-3of12,
      &--tiny-1of4 {
        flex: 0 0 auto;
        width: 3 / 12 * 100%;
      }

      &--tiny-4of12,
      &--tiny-2of6,
      &--tiny-1of3 {
        flex: 0 0 auto;
        width: 4 / 12 * 100%;
      }

      &--tiny-5of12 {
        flex: 0 0 auto;
        width: 5 / 12 * 100%;
      }

      &--tiny-6of12,
      &--tiny-3of6,
      &--tiny-2of4,
      &--tiny-1of2 {
        flex: 0 0 auto;
        width: 6 / 12 * 100%;
      }

      &--tiny-7of12 {
        flex: 0 0 auto;
        width: 7 / 12 * 100%;
      }

      &--tiny-8of12,
      &--tiny-4of6,
      &--tiny-2of3 {
        flex: 0 0 auto;
        width: 8 / 12 * 100%;
      }

      &--tiny-9of12,
      &--tiny-3of4 {
        flex: 0 0 auto;
        width: 9 / 12 * 100%;
      }

      &--tiny-10of12,
      &--tiny-5of6 {
        flex: 0 0 auto;
        width: 10 / 12 * 100%;
      }

      &--tiny-11of12 {
        flex: 0 0 auto;
        width: 11 / 12 * 100%;
      }

      &--tiny-12of12,
      &--tiny-6of6,
      &--tiny-4of4,
      &--tiny-3of3,
      &--tiny-2of2,
      &--tiny-1of1 {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    @media #{$lmn-small-up} {

      &--small-orderFirst {
        order: -1;
      }

      &--small-orderLast {
        order: 999;
      }

      &--small-double {
        flex-grow: 2;
      }

      &--small-triple {
        flex-grow: 3;
      }

      &--small-1of12 {
        flex: 0 0 auto;
        width: 1 / 12 * 100%;
      }

      &--small-2of12,
      &--small-1of6 {
        flex: 0 0 auto;
        width: 2 / 12 * 100%;
      }

      &--small-3of12,
      &--small-1of4 {
        flex: 0 0 auto;
        width: 3 / 12 * 100%;
      }

      &--small-4of12,
      &--small-2of6,
      &--small-1of3 {
        flex: 0 0 auto;
        width: 4 / 12 * 100%;
      }

      &--small-5of12 {
        flex: 0 0 auto;
        width: 5 / 12 * 100%;
      }

      &--small-6of12,
      &--small-3of6,
      &--small-2of4,
      &--small-1of2 {
        flex: 0 0 auto;
        width: 6 / 12 * 100%;
      }

      &--small-7of12 {
        flex: 0 0 auto;
        width: 7 / 12 * 100%;
      }

      &--small-8of12,
      &--small-4of6,
      &--small-2of3 {
        flex: 0 0 auto;
        width: 8 / 12 * 100%;
      }

      &--small-9of12,
      &--small-3of4 {
        flex: 0 0 auto;
        width: 9 / 12 * 100%;
      }

      &--small-10of12,
      &--small-5of6 {
        flex: 0 0 auto;
        width: 10 / 12 * 100%;
      }

      &--small-11of12 {
        flex: 0 0 auto;
        width: 11 / 12 * 100%;
      }

      &--small-12of12,
      &--small-6of6,
      &--small-4of4,
      &--small-3of3,
      &--small-2of2,
      &--small-1of1 {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    @media #{$lmn-medium-up} {

      &--medium-orderFirst {
        order: -1;
      }

      &--medium-orderLast {
        order: 999;
      }

      &--medium-double {
        flex-grow: 2;
      }

      &--medium-triple {
        flex-grow: 3;
      }

      &--medium-1of12 {
        flex: 0 0 auto;
        width: 1 / 12 * 100%;
      }

      &--medium-2of12,
      &--medium-1of6 {
        flex: 0 0 auto;
        width: 2 / 12 * 100%;
      }

      &--medium-3of12,
      &--medium-1of4 {
        flex: 0 0 auto;
        width: 3 / 12 * 100%;
      }

      &--medium-4of12,
      &--medium-2of6,
      &--medium-1of3 {
        flex: 0 0 auto;
        width: 4 / 12 * 100%;
      }

      &--medium-5of12 {
        flex: 0 0 auto;
        width: 5 / 12 * 100%;
      }

      &--medium-half,
      &--medium-6of12,
      &--medium-3of6,
      &--medium-2of4,
      &--medium-1of2 {
        flex: 0 0 auto;
        width: 6 / 12 * 100%;
      }

      &--medium-7of12 {
        flex: 0 0 auto;
        width: 7 / 12 * 100%;
      }

      &--medium-8of12,
      &--medium-4of6,
      &--medium-2of3 {
        flex: 0 0 auto;
        width: 8 / 12 * 100%;
      }

      &--medium-9of12,
      &--medium-3of4 {
        flex: 0 0 auto;
        width: 9 / 12 * 100%;
      }

      &--medium-10of12,
      &--medium-5of6 {
        flex: 0 0 auto;
        width: 10 / 12 * 100%;
      }

      &--medium-11of12 {
        flex: 0 0 auto;
        width: 11 / 12 * 100%;
      }

      &--medium-full,
      &--medium-12of12,
      &--medium-6of6,
      &--medium-4of4,
      &--medium-3of3,
      &--medium-2of2,
      &--medium-1of1 {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    @media #{$lmn-large-up} {

      &--large-orderFirst {
        order: -1;
      }

      &--large-orderLast {
        order: 999;
      }

      &--large-double {
        flex-grow: 2;
      }

      &--large-triple {
        flex-grow: 3;
      }

      &--large-1of12 {
        flex: 0 0 auto;
        width: 1 / 12 * 100%;
      }

      &--large-2of12,
      &--large-1of6 {
        flex: 0 0 auto;
        width: 2 / 12 * 100%;
      }

      &--large-3of12,
      &--large-1of4 {
        flex: 0 0 auto;
        width: 3 / 12 * 100%;
      }

      &--large-4of12,
      &--large-2of6,
      &--large-1of3 {
        flex: 0 0 auto;
        width: 4 / 12 * 100%;
      }

      &--large-5of12 {
        flex: 0 0 auto;
        width: 5 / 12 * 100%;
      }

      &--large-half,
      &--large-6of12,
      &--large-3of6,
      &--large-2of4,
      &--large-1of2 {
        flex: 0 0 auto;
        width: 6 / 12 * 100%;
      }

      &--large-7of12 {
        flex: 0 0 auto;
        width: 7 / 12 * 100%;
      }

      &--large-8of12,
      &--large-4of6,
      &--large-2of3 {
        flex: 0 0 auto;
        width: 8 / 12 * 100%;
      }

      &--large-9of12,
      &--large-3of4 {
        flex: 0 0 auto;
        width: 9 / 12 * 100%;
      }

      &--large-10of12,
      &--large-5of6 {
        flex: 0 0 auto;
        width: 10 / 12 * 100%;
      }

      &--large-11of12 {
        flex: 0 0 auto;
        width: 11 / 12 * 100%;
      }

      &--large-full,
      &--large-12of12,
      &--large-6of6,
      &--large-4of4,
      &--large-3of3,
      &--large-2of2,
      &--large-1of1 {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }


  /* alignment per row */

  &--top {
    align-items: flex-start;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--center {
    align-items: center;
  }

  &--fit {
    > .lmn-grid__cell {
      flex: 1 1 0%;
    }
  }

  &--full {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  &--1of2 {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  &--1of3 {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 33.3333%;
    }
  }

  &--1of4 {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 25%;
    }
  }

  &--1of5 {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 20%;
    }
  }

  &--1of6 {
    > .lmn-grid__cell {
      flex: 0 0 auto;
      width: 16.6666%;
    }
  }

  @media #{$lmn-tiny-up} {

    &--tiny-fit {
      > .lmn-grid__cell {
        flex: 1 1 0%;
      }
    }

    &--tiny-full {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 100%;
      }
    }



    &--tiny-1of2 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    &--tiny-1of3 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 33.3333%;
      }
    }

    &--tiny-1of4 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 25%;
      }
    }

    &--tiny-1of5 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 20%;
      }
    }

    &--tiny-1of6 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 16.6666%;
      }
    }
  }

  @media #{$lmn-small-up} {

    &--small-fit {
      > .lmn-grid__cell {
        flex: 1 1 0%;
      }
    }

    &--small-full {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    &--small-1of2 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    &--small-1of3 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 33.3333%;
      }
    }

    &--small-1of4 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 25%;
      }
    }

    &--small-1of5 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 20%;
      }
    }

    &--small-1of6 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 16.6666%;
      }
    }
  }

  @media #{$lmn-medium-up} {

    &--medium-fit {
      > .lmn-grid__cell {
        flex: 1 1 0%;
      }
    }

    &--medium-full {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    &--medium-1of2 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    &--medium-1of3 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 33.3333%;
      }
    }

    &--medium-1of4 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 25%;
      }
    }

    &--medium-1of5 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 20%;
      }
    }

    &--medium-1of6 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 16.6666%;
      }
    }
  }

  @media #{$lmn-large-up} {

    &--large-fit {
      > .lmn-grid__cell {
        flex: 1 1 0%;
      }
    }

    &--large-full {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    &--large-1of2 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    &--large-1of3 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 33.3333%;
      }
    }

    &--large-1of4 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 25%;
      }
    }

    &--large-1of5 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 20%;
      }
    }

    &--large-1of6 {
      > .lmn-grid__cell {
        flex: 0 0 auto;
        width: 16.6666%;
      }
    }
  }


}

