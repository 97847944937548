.lmn-headline {
  font-family: $lmn-headings-font-family;
  font-weight: $lmn-headings-font-weight;
  letter-spacing: $lmn-headings-letter-spacing;
  margin-top: 0;
  margin-bottom: 0;

  line-height: $lmn-headings-line-height-screen-tiny;

  @media #{$lmn-medium-up} {
    line-height: $lmn-headings-line-height;
  }

  &--primary {
    color: $lmn-color-brand-primary;
  }

  &--secondary {
    color: $lmn-color-brand-secondary;
  }

  &__addendum {
    letter-spacing: 0;
    line-height: 1;
    opacity: 0.7;
  }

  &--h0 {
    font-size: $lmn-font-size-h0-screen-tiny;
    text-transform: $lmn-text-transform-h0;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h0-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h0;
    }

    .lmn-headline__addendum {
      font-size: 65%;
    }
  }

  &--h1 {
    font-size: $lmn-font-size-h1-screen-tiny;
    text-transform: $lmn-text-transform-h1;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h1-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h1;
    }

    .lmn-headline__addendum {
      font-size: 65%;
    }
  }

  &--h2 {
    font-size: $lmn-font-size-h2-screen-tiny;
    text-transform: $lmn-text-transform-h2;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h2-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h2;
    }

    .lmn-headline__addendum {
      font-size: 65%;
    }
  }

  &--h3 {
    font-size: $lmn-font-size-h3-screen-tiny;
    text-transform: $lmn-text-transform-h3;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h3-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h3;
    }

    .lmn-headline__addendum {
      font-size: 65%;
    }
  }

  &--h4 {
    font-size: $lmn-font-size-h4-screen-tiny;
    text-transform: $lmn-text-transform-h4;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h4-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h4;
    }

    .lmn-headline__addendum {
      font-size: 75%;
    }
  }

  &--h5 {
    font-size: $lmn-font-size-h5-screen-tiny;
    text-transform: $lmn-text-transform-h5;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h5-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h5;
    }

    .lmn-headline__addendum {
      font-size: 75%;
    }
  }

  &--h6 {
    font-size: $lmn-font-size-h6-screen-tiny;
    text-transform: $lmn-text-transform-h6;

    @media #{$lmn-medium-up} {
      font-size: $lmn-font-size-h6-screen-small;
    }

    @media #{$lmn-large-up} {
      font-size: $lmn-font-size-h6;
    }

    .lmn-headline__addendum {
      font-size: 75%;
    }
  }

  &--label {
    display: inline-block;
    padding: 0.3em 0.3em 0.20em 0.3em;
    background: $lmn-color-black;
    color: $lmn-color-white;
    text-transform: uppercase;
  }

}

.lmn-headline--text {
  &.lmn-headline--h1,
  &.lmn-headline--h2,
  &.lmn-headline--h3,
  &.lmn-headline--h4,
  &.lmn-headline--h5,
  &.lmn-headline--h6 {
    margin-top: ($lmn-line-height-computed * 1.3);
    margin-bottom: ($lmn-line-height-computed * 0.75);

    &+& {
      margin-top: 0;
    }

    &.lmn-headline--firstInText {
      margin-top: 0;
    }
  }
}
