.lmn-separator {
  display: block;
  border: none;
  width: 100%;
  height: 1px;

  background-color: $lmn-color-gray-medium;

  &--fade {
    background: linear-gradient(to right, transparent, $lmn-color-gray-medium, transparent);
  }

  &--primary {
    height: 2px;
    background-color: $lmn-color-brand-primary;

    &.lmn-separator--fade {
      background: linear-gradient(to right, transparent, $lmn-color-brand-primary, transparent);
    }
  }

  &--secondary {
    height: 2px;
    background-color: $lmn-color-brand-secondary;

    &.lmn-separator--fade {
      background: linear-gradient(to right, transparent, $lmn-color-brand-secondary, transparent);
    }
  }


  &--big {
    height: 0.5rem;
  }
}
