@keyframes lmn-zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1);
  }

  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes lmn-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.lmn-animated {
  animation-fill-mode: both;
  animation-duration: 1000ms;

  &--zoomIn {
    animation-name: lmn-zoomIn;
  }

  &--fadeIn {
    animation-name: fadeIn;
  }
}
