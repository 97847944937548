$lmn-menuToggle-size: 1.25em;

.lmn-menuToggle {
  display: block;
  width: $lmn-menuToggle-size;
  height: $lmn-menuToggle-size;
  position: relative;
  cursor: pointer;


  &__line {
    height: calc(#{$lmn-menuToggle-size} / 12);
    background: currentColor;
    position: absolute;
    left: 0;
    right: 0;
    transition: transform;
    transition-duration: .5s, .25s;

    &:nth-child(2) {
      top: calc(#{$lmn-menuToggle-size} / 5 * 2);
    }

    &:nth-child(3) {
      top: calc(#{$lmn-menuToggle-size} / 5 * 4);
    }
  }

  &.is-open &__line:nth-child(1) {
    transform: translateY(calc(#{$lmn-menuToggle-size} / 5 * 2)) rotate(45deg);
  }
  &.is-open &__line:nth-child(2) {
    opacity: 0;
  }
  &.is-open &__line:nth-child(3) {
    transform: translateY(calc(#{$lmn-menuToggle-size} / 5 * -2)) rotate(-45deg);
  }
}

