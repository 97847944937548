// scss-lint:disable ImportantRule

@mixin lmn-hidden-tiny {
  @media #{$lmn-tiny-only} {
    display: none !important;
  }
}

@mixin lmn-hidden-tiny-up {
  @media #{$lmn-tiny-up}, print {
    display: none !important;
  }
}

@mixin lmn-hidden-tiny-down {
  @include lmn-hidden-tiny;
}


@mixin lmn-hidden-small {
  @media #{$lmn-small-only} {
    display: none !important;
  }
}

@mixin lmn-hidden-small-up {
  @media #{$lmn-small-up}, print {
    display: none !important;
  }
}

@mixin lmn-hidden-small-down {
  @include lmn-hidden-small;
  @include lmn-hidden-tiny-down;
}


@mixin lmn-hidden-medium {
  @media #{$lmn-medium-only} {
    display: none !important;
  }
}

@mixin lmn-hidden-medium-up {
  @media #{$lmn-medium-up}, print {
    display: none !important;
  }
}

@mixin lmn-hidden-medium-down {
  @include lmn-hidden-medium;
  @include lmn-hidden-small-down;
}


@mixin lmn-hidden-large {
  @media #{$lmn-large-only}, print {
    display: none !important;
  }
}

@mixin lmn-hidden-large-up {
  @media #{$lmn-large-up}, print {
    display: none !important;
  }
}

@mixin lmn-hidden-large-down {
  @include lmn-hidden-large;
  @include lmn-hidden-medium-down;
}


@mixin lmn-hidden-xlarge {
  @media #{$lmn-xlarge-only} {
    display: none !important;
  }
}

@mixin lmn-hidden-xlarge-up {
  @media #{$lmn-xlarge-up} {
    display: none !important;
  }
}

@mixin lmn-hidden-xlarge-down {
  @include lmn-hidden-xlarge;
  @include lmn-hidden-large-down;
}

@mixin lmn-hidden-print {
  @media #{$lmn-print-only} {
    display: none !important;
  }
}

@mixin lmn-hidden-screen {
  @media #{$lmn-screen-only} {
    display: none !important;
  }
}
