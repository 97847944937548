@mixin lmn-link($is-unobtrusive: false) {
  transition: color .2s ease-in-out;
  display: inline-block;
  color: $lmn-link-color;
  text-decoration: underline;

  &:visited,
  &:active {
    color: $lmn-link-color;
    text-decoration: underline;
  }

  &:focus {
    color: $lmn-link-hover-color;
    text-decoration: underline;
  }

  @include lmn-hover($lmn-link-hover-color); // scss-lint:disable DeclarationOrder

  @if ($is-unobtrusive == 'modifier') {
    &--unobtrusive {
      @include lmn-hover($lmn-color-brand-primary);

      &,
      &:visited {
        color: $lmn-text-color;
      }
    }
  } @else if($is-unobtrusive == true) {
    @include lmn-hover($lmn-color-brand-primary);

    &,
    &:visited {
      color: $lmn-text-color;
    }
  }
}
