.lmn-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  // scss-lint:disable ColorVariable
  &--backgroundHint {
    background: /* Shadow covers */
            linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
              /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
  // scss-lint:enable ColorVariable

  &--scrollbars {
    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: $lmn-color-brand-primary-inverted;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: $lmn-color-brand-primary;
    }
  }

  &--default {
    max-height: 8.75rem;
  }

}
