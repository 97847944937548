$lmn-row-margin: $lmn-default-margin;
$lmn-row-margin-small: $lmn-default-margin-small;
$lmn-row-margin-extra-small: 2.6px;
$lmn-row-margin-big: $lmn-default-margin-big;
$lmn-row-margin-extra-big: $lmn-default-margin-big * 1.4;
$lmn-row-separator-color: $lmn-color-gray-medium;

.lmn-row {
  margin-bottom: $lmn-row-margin;

  &--bigMargin {
    margin-bottom: $lmn-row-margin-big;
  }

  &--extraBigMargin {
    margin-bottom: $lmn-row-margin-extra-big;
  }

  &--smallMargin {
    margin-bottom: $lmn-row-margin-small;
  }

  &--extraSmallMargin {
    margin-bottom: $lmn-row-margin-extra-small;
  }

  &--first {
    padding-top: $lmn-row-margin / 2;

    &.lmn-row--bigMargin {
      padding-top: $lmn-row-margin-big / 2;
    }

    &.lmn-row--extraBigMargin {
      padding-top: $lmn-row-margin-extra-big / 2;
    }

    &.lmn-row--smallMargin {
      padding-top: $lmn-row-margin-small / 2;
    }
  }

  &--withSeparator {
    border-bottom: 1px solid $lmn-row-separator-color;
    padding-bottom: $lmn-row-margin;

    &.lmn-row--bigMargin {
      padding-bottom: $lmn-row-margin-big;
    }

    &.lmn-row--extraBigMargin {
      padding-bottom: $lmn-row-margin-extra-big;
    }

    &.lmn-row--smallMargin {
      padding-bottom: $lmn-row-margin-small;
    }
  }

  &--inverse {

    margin-bottom: 0;
    margin-top: $lmn-row-margin;

    &.lmn-row--withSeparator {
      padding-bottom: 0;
      border-bottom: none;
      border-top: 1px solid $lmn-color-gray-medium;
      padding-top: $lmn-row-margin;

      // scss-lint:disable NestingDepth
      &.lmn-row--smallMargin {
        padding-top: $lmn-row-margin-small;
      }

      &.lmn-row--bigMargin {
        padding-top: $lmn-row-margin-big;
      }
      // scss-lint:enable NestingDepth

    }
  }
}
