$lmn-badge-background-color-default: $lmn-color-gray-lighter;
$lmn-badge-color-default: $lmn-color-black;
$lmn-badge-background-color-info: $lmn-color-gray-lighter;
$lmn-badge-color-info: $lmn-color-black;
$lmn-badge-background-color-warning: $lmn-color-warning;
$lmn-badge-color-warning: $lmn-color-warning-inverted;
$lmn-badge-background-color-success: $lmn-color-success;
$lmn-badge-color-success: $lmn-color-success-inverted;
$lmn-badge-background-color-danger: $lmn-color-danger;
$lmn-badge-color-danger: $lmn-color-danger-inverted;

.lmn-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;

    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;

    color: $lmn-badge-color-default;
    background: $lmn-badge-background-color-default;

    transition: color 300ms, background-color 300ms;

    &--info {
        color: $lmn-badge-color-info;
        background: $lmn-badge-background-color-info;
    }

    &--warning {
        color: $lmn-badge-color-warning;
        background: $lmn-badge-background-color-warning;
    }

    &--danger {
        color: $lmn-badge-color-danger;
        background: $lmn-badge-background-color-danger;
    }

    &--success {
        color: $lmn-badge-color-success;
        background: $lmn-badge-background-color-success;
    }
}