// scss-lint:disable ImportantRule
.lmn-hidden {

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  display: none !important;

  &--preserveSpace {
    display: inherit !important;
    opacity: 0;
  }

  &.is-unhidden {
    display: inherit !important;

    &.lmn-hidden--fadeInOnUnhidden {
      animation: fadeIn 0.5s;
    }

    &.lmn-hidden--preserveSpace {
      opacity: 1;
    }

  }

}
