.lmn-appliedFilter {
  display: inline-flex;
  align-items: center;
  margin: $lmn-default-margin/2 $lmn-default-margin $lmn-default-margin/2 0;
  border: 1px solid $lmn-color-brand-primary;
  background-color: $lmn-color-white;
  padding: 0.3rem 0.6rem 0.2rem 0.6rem;
  cursor: pointer;
  font-size: 14px;
  max-width: 100%;

  @media #{$lmn-small-up} {
    min-width: 7rem;
  }

  &__label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto;
  }

  &__close {
    flex: 0 0 auto;
    margin-left: 0.5rem;
    margin-top: -2px;
  }

  &:hover > &__close,
  &:active > &__close{
    color: $lmn-color-error;
  }

}
