// scss-lint:disable SelectorFormat
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Body reset

html {
  -webkit-tap-highlight-color: $lmn-color-transparent;
}

html,
body {
  margin: 0;
  font-family: $lmn-font-family-base;
  font-size: $lmn-font-size-base;
  line-height: $lmn-line-height-base;
  color: $lmn-text-color;
  background-color: $lmn-body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea,
table,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

th {
  text-align: left;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
p,
fieldset,
figure,
input {
  margin: 0;
  padding: 0;
}

input {
  border-radius: 0;
}

iframe,
fieldset {
  border: none;
}

ul,
ol {
  list-style-type: none;
}

li {
  list-style-image: none;
}

a,
a:visited,
a:active,
a:focus {
  @include lmn-hover('inherit');
  color: inherit;
  text-decoration: none;
}



img {
  max-width: 100%;
}

hr {
  border: none;
  border-top: 1px solid $lmn-color-gray-medium;
  margin: $lmn-default-margin 0;
}

small {
  font-size: 1em;
}

