.lmn-float {

  &--clear::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  &--inText {
    margin-bottom: 0.3rem;
  }

  &--left {
    float: left;

    &.lmn-float--inText {
      margin-right: 0.5rem;
    }
  }

  &--right {
    float: right;

    &.lmn-float--inText {
      margin-left: 0.5rem;
    }
  }

  &--smallCenter {
    @media #{$lmn-small-down} {
      float: none;
      display: block;

      &.lmn-float--inText {
        text-align: center;

        margin-left: auto;
        margin-right: auto;

      }
    }
  }
}