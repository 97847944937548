$lmn-screenMargin: $lmn-default-margin;
$lmn-screenMargin-screen-large: $lmn-default-margin-screen-large;

.lmn-screenMargin {
  margin-left: $lmn-screenMargin;
  margin-right: $lmn-screenMargin;

  @media #{$lmn-large-up} {
    margin-left: $lmn-screenMargin-screen-large;
    margin-right: $lmn-screenMargin-screen-large;
  }

  .lmn-screenMargin {
    margin-left: 0;
    margin-right: 0;
  }

  &--smallNone {
    @media #{$lmn-small-down} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--undo {
    margin-left: -$lmn-screenMargin !important;
    margin-right: -$lmn-screenMargin !important;;

    @media #{$lmn-large-up} {
      margin-left: -$lmn-screenMargin-screen-large !important;;
      margin-right: -$lmn-screenMargin-screen-large !important;;
    }
  }
}
