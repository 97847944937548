  .lmn-postfix {

    display: flex;

    &--center {
      align-items: center;
    }

    &--top {
      align-items: flex-start;
    }

    &--bottom {
      align-items: flex-end;
    }

    &--gutters {
      > * + * {
        margin-left: $lmn-default-margin;

      }
    }

    &--tiny-stacked,
    &--small-stacked {
      @media #{$lmn-tiny-only} {
        flex-direction: column;
        align-items: flex-start;

        &.lmn-postfix--gutters {
          > * + * {
            margin-left: 0;
            margin-top: $lmn-default-margin;
          }
        }
      }
    }

    &--small-stacked {
      @media #{$lmn-small-only} {
        flex-direction: column;
        align-items: flex-start;

        > * + * {
          margin-left: 0;
          margin-top: $lmn-default-margin;
        }
      }
    }

    &__fixed {
      flex-shrink: 0;
    }

    &__fluid {
      flex: 1 1 auto;
    }


  }
