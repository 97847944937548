.lmn-accordion {

  &__item {

    border-bottom: 1px solid $lmn-color-gray-medium;

    &--last,
    &:last-child {
      border-bottom: none;
    }

    &Header {
      @include lmn-hover($lmn-color-brand-primary);
      padding-top: 1rem;
      padding-bottom: 0.8rem;
      cursor: pointer;

      .lmn-accordion__item.is-open > & {
        color: $lmn-color-brand-primary;
        font-weight: bold;
      }
    }

    &Headline {
      font-size: 18px;
    }

    &Content {
      padding-top: 0.2rem;
      padding-bottom: 1rem;
    }
  }


}

